<template>
  <section class="career-section">
    <div class="career-section__header">
      <BreadCrumb class="top-left" />

      <div class="career-section__header__banner do-flex">
        <div class="career-section__header__banner__text">
          <div class="career-section__header__banner__text__title">
            Siap Jadi Bagian<br>#BeraniDiandelin?
          </div>
          <div class="career-section__header__banner__text__desc">
            Bergabunglah Bersama Kami
          </div>
        </div>
        <ImageLazy
          class="career-section__header__banner"
          :src="imgSrcBanner"
          alt="Karir Lion Parcel"
          @error="(event) => setImageDefault(event, 'career')"
        />
      </div>
    </div>
    <CareerFilter @filter="onFilter" />
    <div class="career-section__company-value">
      <div class="career-section__company-value__text">
        <div class="career-section__company-value__text__title">
          Nilai Perusahaan: Trust The Team
        </div>
        <div class="career-section__company-value__text__desc">
          Saling terbuka dalam berkomunikasi dan mempercayai anggota tim untuk
          menciptakan hasil kerja tim yang efektif.
        </div>
      </div>
      <div class="career-section__company-value__image">
        <ImageLazy
          class="career-section__company-value__image__collections"
          :src="'/images/career/trustheteam-dekstop.webp'"
          alt="career company value"
          style="z-index: 5;"
          @error="(event) => setImageDefault(event, 'collections')"
        />
        <img
          v-if="!isMobileSize"
          class="line"
          :src="'/images/career/career_company_value_line.svg'"
          alt="line"
        >
      </div>
    </div>
    <div class="career-section__event">
      <div class="career-section__event__text">
        Yuk, Intip Keseruan & Kebersamaan Tim di Lion Parcel
      </div>
      <EventsCarousel />
    </div>
    <div class="career-section__employee-experience">
      <div class="career-section__employee-experience__text">
        <div class="career-section__employee-experience__text__title">
          Cerita Seru Karyawan Lion&nbsp;Parcel
        </div>
        <div class="career-section__employee-experience__text__desc">
          Intip kesan selama kerja di Lion Parcel yuk!
        </div>
      </div>
      <EmployeeCard
        :loading="isLoadingEmployeeBranding"
        :data="employeeBrandingData"
      />
    </div>
    <div class="career-section__footer">
      <div class="career-section__footer__banner">
        <ImageLazy
          class="career-section__footer__banner__image"
          :src="imgSrcFooterBanner"
          alt="career banner footer"
          width="100%"
          height="300px"
          @error="(event) => setImageDefault(event, 'career')"
        />
        <div class="career-section__footer__banner__text">
          <div class="career-section__footer__banner__text__title">
            Ayo! Jadi Bagian #BeraniDiandelin
          </div>
          <div
            class="career-section__footer__banner__text__cta"
            @click="gotoJobsPage"
          >
            <div
              class="career-section__footer__banner__text__cta__button shadow"
            />
            <div class="career-section__footer__banner__text__cta__button">
              <span>
                Mulai Karir Anda
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { mapActions } from 'vuex';
import BreadCrumb from '@/components/new-breadcrumb/BreadCrumb';
import CareerFilter from '@/pages/new-career/components/CareerFilter.vue';
import EventsCarousel from '@/pages/new-career/components/EventsCarousel.vue';
import EmployeeCard from '@/pages/new-career/components/EmployeeCard.vue';
import ImageLazy from '@/components/ImageLazy/index.vue';
import { setImageDefault } from '@/components/ImageLazy/util';
import mixinMobile from '@/misc/mixinMobile';
import mixinMetaInfo from '@/misc/mixinMetaInfo';
import CareerUseCase from '@/pages/new-career/app/usecase/career';

export default {
  components: {
    BreadCrumb, CareerFilter, EventsCarousel, EmployeeCard, ImageLazy,
  },
  mixins: [mixinMobile, mixinMetaInfo],
  metaInfo() {
    return this.SEO({
      channel: 'channel',
      title: this.metaInfo.meta_info_title,
      description: this.metaInfo.meta_info_description,
      keywords: this.metaInfo.meta_info_keyword,
      publishedTime: this.metaInfo.meta_info_publish_time,
      image: this.metaInfo.attachment_meta_info_photo_url,
    });
  },
  data() {
    return {
      setImageDefault,
      searchMetaInfo: '_career',
      employeeBrandingData: [],
      isLoadingEmployeeBranding: true,
    };
  },
  computed: {
    imgSrcBanner() {
      return this.windowWidth <= 599
        ? '/images/career/banner_career_mobile.webp'
        : '/images/career/banner_career.webp';
    },
    imgSrcFooterBanner() {
      return this.windowWidth <= 599
        ? '/images/career/career_cta_mobile.webp'
        : '/images/career/career_cta_desktop.webp';
    },
  },
  mounted() {
    this.getEmployeeBranding();
    window.scrollTo(0, 0);
    this.resetFilter();
  },
  methods: {
    ...mapActions(['resetFilter']),
    async getEmployeeBranding() {
      this.isLoadingEmployeeBranding = true;
      const { data } = await CareerUseCase.getEmployeeBranding();
      if (data && data.data) {
        this.employeeBrandingData = data.data;
        this.isLoadingEmployeeBranding = false;
      }
    },
    gotoJobsPage() {
      this.$router.push('/career/jobs');
    },
    onFilter() {
      this.$router.push('/career/jobs');
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./style.scss";
</style>
