<!-- eslint-disable vue/no-v-html -->
<template>
  <div class="employee-experience">
    <div class="employee-experience__photo">
      <div class="employee-experience__photo__image">
        <template v-if="loading">
          <div class="skeleton-box" />
        </template>
        <template v-else>
          <ImageLazy
            v-for="(item, i) in data"
            :key="i"
            :src="item.attachment_employee_branding_photo_url"
            alt="employee photo"
            @touchstart="onTouchStart"
            @touchend="onTouchEnd"
            @error="(event) => setImageDefault(event, 'employee-photo')"
          />
        </template>
      </div>
      <div class="employee-experience__photo__indicator">
        <template v-if="loading">
          <div
            class="skeleton-box"
            style="width: 80px; height: 10px;"
          />
        </template>
        <template v-else>
          <div
            v-for="(_item, i) in data"
            :key="`indicator-${i}`"
            class="employee-experience__photo__indicator__item"
            :class="i === currentIdx && 'active'"
            @click="() => slideEmployeePhoto(i)"
          />
        </template>
      </div>
    </div>
    <div class="employee-experience__text">
      <template v-if="loading">
        <div
          class="skeleton-box"
          style="width: 100%; height: 10px;"
        />
      </template>
      <template v-else>
        <div
          class="employee-experience__text__experiences"
          v-html="data[currentIdx].testimonial"
        />
      </template>
      <div class="employee-experience__text__identity">
        <div class="employee-experience__text__identity__name">
          <template v-if="loading">
            <div
              class="skeleton-box"
              style="width: 100px; height: 10px;"
            />
          </template>
          <template v-else>
            {{ data[currentIdx].employee_name }}
          </template>
        </div>
        <div class="employee-experience__text__identity__position">
          <template v-if="loading">
            <div
              class="skeleton-box"
              style="width: 70px; height: 10px;"
            />
          </template>
          <template v-else>
            {{ data[currentIdx].employee_position }}
          </template>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import mixinMobile from "@/misc/mixinMobile";
import ImageLazy from '@/components/ImageLazy/index.vue';
import { setImageDefault } from '@/components/ImageLazy/util';

export default {
  components: {ImageLazy},
  mixins: [mixinMobile],
  props: {
    data: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      currentIdx: 0,
      touchStartX: 0,
      touchEndX: 0,
      setImageDefault
    };
  },
  computed: {
    decideImageWidth() {
      return this.getScreenWidth();
    },
  },
  mounted() {
    window.addEventListener("resize", this.onResize);
  },
  destroyed() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    getScreenWidth() {
      if (this.windowWidth <= 320) {
        return 56.6;
      }
      if (this.windowWidth <= 599) {
        return 74.6;
      }
      return 126.83;
    },
    onResize() {
      this.slideEmployeePhoto(this.currentIdx);
    },
    slideEmployeePhoto(idx) {
      document.querySelector(
        ".employee-experience__photo__image"
      ).style.transform = `translateX(${-idx * this.decideImageWidth}px)`;
      this.currentIdx = idx;
    },
    onTouchStart(e) {
      this.touchStartX = e.changedTouches[0].screenX;
    },
    onTouchEnd(e) {
      this.touchEndX = e.changedTouches[0].screenX;
      this.checkDirection();
    },
    checkDirection() {
      if (this.touchEndX < this.touchStartX) {
        if (this.currentIdx !== this.data.length - 1) {
          this.currentIdx++;
          this.slideEmployeePhoto(this.currentIdx);
        }
      }
      if (this.touchEndX > this.touchStartX) {
        if (this.currentIdx) {
          this.currentIdx--;
          this.slideEmployeePhoto(this.currentIdx);
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/css/style.scss";

.employee-experience {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 24px;
  width: 549px;
  height: 258px;
  background: $color-white;
  border: 1px solid $color-hover-gray;
  box-shadow: 0px 8px 15px rgba(26, 20, 33, 0.15);
  border-radius: 12px;

  @include for-size(mobile) {
    width: unset;
    height: unset;
    padding: 16px;
  }

  @media (max-width: 320px) {
    padding: 8px;
  }

  &__photo {
    margin-right: 24px;
    display: flex;
    flex-direction: column;
    width: 126.83px;
    overflow: hidden;

    @include for-size(mobile) {
      width: 74.6px;
      margin-right: 16px;
    }

    @media (max-width: 320px) {
      margin-right: 12px;
      width: 56.6px;
    }

    &__image {
      display: flex;
      flex-direction: row;
      width: 126.83px;
      height: 169.72px;
      transition: all 0.5s ease;
      border-radius: 8px;

      & > div {
        min-width: 126.83px;
        min-height: 169.72px;
        height: 100%;
        width: 100%;
        border-radius: 8px;

        @include for-size(mobile) {
          min-width: 74.6px;
          min-height: 99.84px;
          height: 100%;
        }

        @media (max-width: 320px) {
          min-width: 56.6px;
          min-height: 75.72px;
        }

        & ::v-deep img {
          width: 126.83px;
          height: 169.72px;
          border-radius: 8px;

          @include for-size(mobile) {
            width: 74.6px;
            height: 99.84px;
            height: 100%;
          }

          @media (max-width: 320px) {
            width: 56.6px;
            height: 75.72px;
          }
        }
      }

      @include for-size(mobile) {
        width: 74.6px;
        height: 100px;
      }

      @media (max-width: 320px) {
        width: 56.6px;
        height: unset;
      }
    }

    &__indicator {
      margin-top: 22px;
      display: flex;
      flex-direction: row;

      &__item {
        width: 10px;
        height: 8px;
        background: $color-gray-pensive-5;
        border-radius: 6px;
        margin-right: 4px;
        transition: all 0.5s ease-in-out;
        cursor: pointer;

        @include for-size(mobile) {
          width: 5px;
          height: 4px;
          border-radius: 2px;
          margin-right: 2px;
        }

        &:last-child {
          margin-right: 0px;
        }

        &.active {
          width: 32px;
          background: $color-base;

          @include for-size(mobile) {
            width: 16px;
          }
        }
      }
    }
  }

  &__text {
    width: 350.17px;
    height: 167px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    @include for-size(mobile) {
      width: 189.4px;
      height: 161px;
    }

    &__experiences {
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      color: $color-grey;

      @include for-size(mobile) {
        font-weight: 500;
        font-size: 10px;
        line-height: 15px;
      }
    }

    &__identity {
      &__name {
        margin-top: 16px;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: $color-indigo-dark;

        @include for-size(mobile) {
          font-weight: 400;
          font-size: 12px;
          line-height: 18px;
        }

        @media (max-width: 320px) {
          margin-top: 5px;
        }
      }

      &__position {
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        color: $color-gray-shade;

        @include for-size(mobile) {
          font-weight: 400;
        }
      }
    }
  }
}

.image-default__event-item {
  padding-top: 80px;
  text-align: center;

  @include image-default(126.83px, 169.77px);

  @include for-size(mobile) {
    font-size: 10px;
    @include image-default(74.6px, 100px);
  }

  @media (max-width: 320px) {
    font-size: 10px;
    @include image-default(56.6px, 75.57px)
  }
}
</style>
