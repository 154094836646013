<template>
  <div class="event-carousel__container">
    <div
      ref="carousel"
      class="event-carousel__carousel"
    >
      <div
        v-for="(item, i) in items"
        :key="i"
        class="event-carousel__carousel__item"
      >
        <ImageLazy
          :src="`/images/career/${item.image}`"
          :alt="`event ${i + 1}`"
          @error="(event) => setImageDefault(event, 'event-item')"
        />
        <span>{{ item.title }}</span>
      </div>
    </div>
    <div class="event-carousel__button">
      <div class="event-carousel__button__container">
        <div
          class="event-carousel__button__container__item"
          @click="prev"
        >
          <ImageLazy
            class="event-carousel__button__container__item__icon"
            :src="'/images/ics_o_arrow_left_alt_lg.svg'"
            alt="arrow left"
          />
        </div>
        <div
          class="event-carousel__button__container__item"
          @click="next"
        >
          <ImageLazy
            class="event-carousel__button__container__item__icon"
            :src="'/images/ics_o_arrow_right_alt_lg.svg'"
            alt="arrow right"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ImageLazy from "@/components/ImageLazy/index.vue";
import { setImageDefault } from "@/components/ImageLazy/util";
import mixinMobile from "@/misc/mixinMobile";

export default {
  components: { ImageLazy },
  mixins: [mixinMobile],
  data() {
    return {
      items: [
        {
          title: "Marketing Town Hall",
          image: "event1.webp",
        },
        {
          title: "Tech Townhall",
          image: "event2.webp",
        },
        {
          title: "Futsal Rutin Lion Parcel",
          image: "event3.webp",
        },
      ],
      currentImageNo: 1,
      translateXPixel: 0,
      setImageDefault,
    };
  },
  computed: {
    decideTranslateWidth() {
      return this.windowWidth <= 599 ? 244 : 456;
    },
  },
  mounted() {
    this.calculateCarousel();
    window.addEventListener("resize", this.calculateCarousel);
  },
  destroyed() {
    window.removeEventListener("resize", this.calculateCarousel);
  },
  methods: {
    prev() {
      const previewEl = document.querySelector(".preview")
      if (previewEl) {
        previewEl.classList.remove("preview");
      } 
      if (this.currentImageNo < 2) {
        const item = document.querySelectorAll(
          ".event-carousel__carousel__item"
        )[this.currentImageNo + 1];
        const cloneItem = item.cloneNode(true);
        this.$refs.carousel.prepend(cloneItem);
        document
          .querySelectorAll(".event-carousel__carousel__item")[this.currentImageNo]
          .classList
          .add("preview");
      } else {
        if (this.windowWidth > 600) {
          this.translateXPixel =
            this.translateXPixel - this.decideTranslateWidth;
          this.$refs.carousel.style.transform = `translateX(-${this.translateXPixel}px)`;
        } else {
          this.translateXPixel =
            this.translateXPixel + this.decideTranslateWidth;
          this.$refs.carousel.style.transform = `translateX(${this.translateXPixel}px)`;
        }
        this.currentImageNo--;
        const currentCarouselItemEl = document.querySelectorAll(".event-carousel__carousel__item")[this.currentImageNo]
        
        if (currentCarouselItemEl) {
          currentCarouselItemEl.classList.add("preview");
        }
        this.items.pop();
      }
    },
    next() {
      this.items.push(this.items[this.currentImageNo - 1]);
      document.querySelector(".preview").classList.remove("preview");

      if (this.windowWidth > 600) {
        this.translateXPixel =
          150 + this.currentImageNo * this.decideTranslateWidth;
        this.$refs.carousel.style.transform = `translateX(-${this.translateXPixel}px)`;
      } else {
        this.translateXPixel =
          192 - this.currentImageNo * this.decideTranslateWidth;
        this.$refs.carousel.style.transform = `translateX(${this.translateXPixel}px)`;
      }
      this.currentImageNo++;
      const currentCarouselItemEl = document.querySelectorAll(".event-carousel__carousel__item")[this.currentImageNo]

      if (currentCarouselItemEl) {
        currentCarouselItemEl.classList.add("preview");
      }    
    },
    setInitPreviewClass() {
      document
        .querySelectorAll(".event-carousel__carousel__item")[1]
        .classList.add("preview");
    },
    calculateCarousel() {
      this.setInitPreviewClass();
      this.currentImageNo = 1;
      if (this.windowWidth > 600) {
        this.translateXPixel = 150;
        this.$refs.carousel.style.transform = `translateX(-150px)`;
      } else {
        this.translateXPixel = 192;
        this.$refs.carousel.style.transform = `translateX(192px)`;
      }
    }
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/css/style.scss";
.event-carousel {
  &__container {
    width: 1152px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  &__carousel {
    margin-top: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    height: 356px;
    transition: all 0.5s;

    @include for-size(mobile) {
      height: 195px;
    }

    &__item {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      transition: all 0.6s;

      & div {
        width: 436px;
        height: 245px;
        transition: all 0.6s;

        & ::v-deep img {
          border-radius: 8px;
        }

        @include for-size(mobile) {
          width: 224px;
          height: 125.87px;
        }
      }

      & span {
        margin-top: 20px;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: $color-indigo-dark;

        @include for-size(mobile) {
          font-size: 10px;
          line-height: 15px;
        }
      }

      &.preview {
        & div {
          width: calc(436px * 1.24);
          height: calc(245px * 1.24);

          & ::v-deep img {
            filter: drop-shadow(0px 20px 35px rgba(26, 20, 33, 0.15));
          }

          @include for-size(mobile) {
            width: calc(224px * 1.24);
            height: calc(125.87px * 1.24);
          }
        }

        & span {
          font-size: 20px;
          line-height: 30px;

          @include for-size(mobile) {
            font-size: 14px;
            line-height: 21px;
          }
        }
      }
    }
  }

  &__button {
    margin-top: 40px;
    display: flex;
    justify-content: center;
    align-items: center;

    @include for-size(mobile) {
      margin-top: 20px;
    }

    &__container {
      display: flex;
      flex-direction: row;

      &__item {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
        background: $color-base;
        border-radius: 24px;
        cursor: pointer;

        & div {
          display: flex;
          justify-content: center;
        }

        &__icon ::v-deep img {
          @include for-size(mobile) {
            width: 18px;
            height: 18px;
          }
        }

        @include for-size(mobile) {
          width: 36px;
          height: 36px;
        }

        &:first-child {
          margin-right: 20px;
        }
      }
    }
  }
}

.image-default__event-item {
  padding-top: 80px;
  text-align: center;

  @include image-default(350px, 350px);
}
</style>
